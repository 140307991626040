
import {useHttp} from '../hooks/http.hooks';

const useMarketService = () => {
    const {loading, request,  error, clearError, successfull, errorMessage} = useHttp();
    const _url =  process.env.REACT_APP_SERVER_URL  || "https://f9fd09879062.vps.myjino.ru:49256"
     
    const getProducts = async (catalog) => {
      
        const res = await request(`${_url}/${catalog}`)
  
            
        switch(catalog){
            case 'allproducts': 
                return res.map(transformAllProduct);
            case 'grids-one':
                return res.map(transformGridsOne);
            case 'grids-two': 
                return res.map(transformGridsTwo);
            case 'grids': 
                return res.map(transformGrids);
            case 'conditioner-protection': 
                return res.map(transformConditionerProtection);
            case 'visors':
                return res.map(transformVisors); 
            case 'baskets':
                return res.map(transformBaskets); 
            case 'anti-theft': 
                return res.map(transformAntiTheft);  
            case 'woodcutters': 
                return res.map(transformWoodcutters); 
            case 'birdhouses': 
                return res.map(transformBirdhouses);
            case 'pergolias': 
                return res.map(transformPergolias);
            case 'swings': 
                return res.map(transformSwings);
            case 'flags': 
                return res.map(transformFlags);      
            case 'porches': 
                return res.map(transformProches);
            case 'plates': 
                return res.map(transformPlates);
            case 'memory': 
                return res.map(transformMemory);
            default: 
                return res
        }
        
    }

    const getProductsForArticle = async (catalog, article) => {
     
        const res = await request(`${_url}/${catalog}${article}`)
      
             return res
        // switch(num){
        //     case 0: 
        //         return res.map(transformAllProduct);
        //     case 1:
        //         return res.map(transformGridsOne);
        //     case 2:
        //         return res.map(transformVisors); 
        //     case 3:
        //         return res.map(transformBaskets); 
        //     case 4: 
        //         return res 
        //     case 5: 
        //         return res.map(transformGridsTwo);
        //     case 6: 
        //         return res.map(transformGrids);
        //     case 7: 
        //         return res.map(transformConditionerProtection);

        //     default: 
        //         break
        // }
        
    }
    const newOrder = async (url, order) => {
        
        const res = await request(
                                    `${_url}/${url}`, 
                                    'POST', 
                                    JSON.stringify(order), 
                                    {'Content-Type': 'application/json'}
                                    )
      
        return res
    }

    const newFeedback = async (feedback) => {
        
        const res = await request(
                                    `${_url}/new-feedback`, 
                                    'POST', 
                                    JSON.stringify(feedback), 
                                    {'Content-Type': 'application/json'}
                                    )
      
        return res
    }

    const handleRegistration = async (data) => {
    
        const res = await request(`${_url}/register`, "POST", JSON.stringify(data),   {'Content-Type': 'application/json'});
        return res
     }; 

     const handleLogin = async (data) => {
       
        const res = await request(`${_url}/login`, "POST", JSON.stringify(data),   {'Content-Type': 'application/json'});
        return res
    };

    const handleSendAuth = async (data) => {
       
        const res = await request(`${_url}/send-auth`, "POST", JSON.stringify(data),   {'Content-Type': 'application/json'});
        return res
    };

    const getUser = async (data) => {
       
        const res = await request(`${_url}/users`, "POST", JSON.stringify(data),   {'Content-Type': 'application/json'});
        return res
    };

    const getOrders = async (data) => {
       
        const res = await request(`${_url}/orders-by-user`, "POST", JSON.stringify(data),   {'Content-Type': 'application/json'});
        return res.orders
    };

    const setName = async (id, data) => {
       
        const res = await request(`${_url}/users/${id}`, "POST", JSON.stringify(data),   {'Content-Type': 'application/json'});
        return res.orders
    };
    
    const resetPassword = async (data) => {
       
        const res = await request(`${_url}/reset-password`, "POST", JSON.stringify(data),   {'Content-Type': 'application/json'});
        return res.orders
    };

    const updatePassword = async (data) => {
       
        const res = await request(`${_url}/update-password`, "POST", JSON.stringify(data),   {'Content-Type': 'application/json'});
        return res.orders
    };

    const getAllOrders = async (userId) => {
       
        const res = await request(`${_url}/allorders/${userId}`, "GET");
        return res 
    };

    const uploadFile = async (data  ) => {  
        const res = await request(`${_url}/upload`, "POST", JSON.stringify(data), {'Content-Type': 'application/json'});
        return res 
    };


    
    const transformAllProduct = (product) => {
        product.categories = [
                {
                    name: 'Каталог',
                    link:'/',
                    nameList: 'Каталог'
                    
                } 
            ]
        return product;
    }

    const transformGridsOne = (grid) => {
        grid.categories = [
            {
                name: 'Каталог',
                link:'/',
                nameList: 'Решетки одностворчатые'
            },
            {
                name: 'Решетки',
                link: '/grids'
            },
            {
                name: 'Одностворчатые',
                link: '/grids/grids-one'
            },
        ]
        return grid;
    }

    const transformGridsTwo = (grid) => {
        grid.categories = [
                {
                    name: 'Каталог',
                    link:'/',
                    nameList: 'Решетки двухстворчатые'
                },
                {
                    name: 'Решетки',
                    link:'/grids'
                },
                {
                    name: 'Двухстворчатые',
                    link:'/grids/grids-two'
                }
            ]
        return grid;
    }

    const transformGrids = (grid) => {
        grid.categories = [
            {
                name: 'Каталог',
                link:'/',
                nameList: 'Решетки'
            },
            {
                name: 'Решетки',
                link: '/grids'
            }
    ]
        return  grid
    }

    const transformConditionerProtection = (item) => {
        item.categories = [
                {
                    name: 'Каталог',
                    link: '/',
                    nameList: 'Защита кондиционера'
                },
                {
                    name: 'Защита кондицонера',
                    link: '/conditioner-protection'
                }]


        return item
    }

    const transformVisors = (visor) => {
        visor.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Защитные козырьки'
            },
            {
                name: 'Защита кондицонера',
                link: '/conditioner-protection'
            },
            {
                name: 'Козырьки',
                link: '/visors'
            },
        ]


        return visor
    }

    const transformBaskets = (baskets) => {
        baskets.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Корзины'
            },
            {
                name: 'Защита кондицонера',
                link: '/conditioner-protection'
            },
            {
                name: 'Корзины',
                link: '/baskets'
            }] 


        return baskets
    }
    
    const transformAntiTheft = (antiTheft) => {
        antiTheft.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Противоугонные устройства'
            },
            {
                name: 'Противоугонные устройства',
                link: '/anti-theft'
            }]
        return antiTheft
        
    }

    const transformWoodcutters = (woodcutters) => {
        woodcutters.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Дровницы'
            },
            {
                name: 'Дровницы',
                link: '/woodcutters'
            }]
        return woodcutters
        
    }

    const transformBirdhouses = (birdhouses) => {
        birdhouses.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Кормушки для птиц'
            },
            {
                name: 'Кормушки для птиц',
                link: '/birdhouses'
            }]
        return birdhouses
        
    }

    const transformPergolias = (pergolias) => {
        pergolias.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Перголы'
            },
            {
                name: 'Перголы',
                link: '/pergolias'
            }]
        return pergolias
        
    }

    const transformSwings = (swings) => {
        swings.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Качели'
            },
            {
                name: 'Перголы',
                link: '/swings'
            }]
        return swings
        
    }

    const transformFlags = (swings) => {
        swings.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Флагштоки'
            },
            {
                name: 'Флагштоки',
                link: '/flags'
            }]
        return swings
        
    }


    const transformProches = (porches) => {
        porches.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Козырьки и ступеньки'
            },
            {
                name: 'Козырьки и ступеньки',
                link: '/porches'
            }]
        return porches
        
    }

    const transformPlates = (plates) => {
        plates.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Пластины'
            },
            {
                name: 'Пластины',
                link: '/plates'
            }]
        return plates
        
    }

    const transformMemory = (memory) => {
        memory.categories = [
            {
                name: 'Каталог',
                link: '/',
                nameList: 'Ритуальные изделия'
            },
            {
                name: 'Ритуальные изделия',
                link: '/memory'
            }]
        return memory
        
    }

    return {
        getProducts,
        newOrder,
        getProductsForArticle,
        handleRegistration,
        handleLogin,
        handleSendAuth,
        getUser,
        getOrders,
        loading, error, clearError, successfull, newFeedback, errorMessage, 
        setName,
        resetPassword, 
        updatePassword,
        getAllOrders,
        uploadFile
    }
}

export default useMarketService;