import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'; 
import ProductCounter from "../../../productsList/productCounter"; 
import './../../../StylesProduct/description.scss'


const Description = ({productFlag}) => {
 

    const elem = (productFlag) => {
        const {name_of_product, annotation,  
               main_photo_link, additional_photo_links} = productFlag;
        
  
        return(
            <div className="description">
                <div className="carousel-slide">
                    <Carousel showStatus={false}>
                        <div>
                            <img src={main_photo_link} alt={name_of_product}/>
                        </div>
                        <div>
                            <img src={additional_photo_links} alt={name_of_product}/>
                        </div>
                    </Carousel>
 
                </div>
                <div className="definition"> 
                    <ProductCounter
                        item={productFlag} 
                    /> 
                    <div className="definition__description">
                        <div>{annotation}</div>
                    </div>

                </div>
            </div>
        )
    }
   
    const item = productFlag ? elem(productFlag[0]) : null
    return (
       <>
         {item}
       </>
    )
}

export default Description;